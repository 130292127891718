/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import media from '../media';

const TeamPhoto = styled.div`
  width: 100%;
  position: relative;
  flex: 0 0 64px;
  display: inline-flex;

  ${media.md`
    margin-bottom: 1rem;
  `}

  img {
    width: 100%;
    height: 100%;
    height: intrinsic;
    margin-bottom: 0;
  }
`;

const StyledTeamMember = styled.div`
  /* display: flex; */
  justify-content: flex-start;
  align-items: center;
  align-items: flex-start;

  h3 {
    margin-bottom: 0.5rem;
  }
  h5 {
    margin-bottom: 0;
  }

  ${TeamPhoto} {
    margin-right: 1rem;
  }
  ${media.md`
    display: block;
  `}
`;

const TeamMember = ({ image, name, title }) => (
  <StyledTeamMember>
    <TeamPhoto sx={{ bg: 'accent' }}>
      <img src={image} alt={`${name}: ${title}`} />
    </TeamPhoto>
    <div>
      <Styled.h3 sx={{ mb: '0!important' }}>{name}</Styled.h3>
      <Styled.p>{title}</Styled.p>
    </div>
  </StyledTeamMember>
);

TeamMember.defaultProps = {
  image: 'image goes here',
  name: 'Zach Panda',
  title: 'Lead Amphibian Strategist',
  isLeadership: false,
};

TeamMember.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
};

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em;
  
  ${media.md`
    grid-template-columns: repeat(2, 1fr);
  `}
  
  ${media.lg`
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 55px;
  `}
  
  ${media.xl`
    grid-template-columns: repeat(4, 1fr);
    /* new designs */
    /* max-width: 1029px; */
    grid-gap: 6.875em; /* 110px */
    grid-gap: 94px; /* 110px */

    grid-gap: 55px;
  `}
`;

const LocationGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  ${media.lg`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

const TeamLocation = ({ name, children }) => (
  <div sx={{ bg: 'transparent' }}>
    <Styled.h3>icon</Styled.h3>
    <Styled.h2>{name}</Styled.h2>
    <Styled.p>{children}</Styled.p>
  </div>
);

TeamLocation.propTypes = {
  name: PropTypes.string,
  children: PropTypes.string,
};

export { TeamMember, TeamGrid, LocationGrid, TeamLocation };
