/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';
import styled from '@emotion/styled';
import { Arrow } from '../assets/VectorAssets';

const StyledCareerLink = styled.a`
  color: initial;
  text-decoration: none;
  margin-bottom: 1.5rem;
  transition: 100ms ease-in color;
  display: flex;
  align-items: center;

  span {
    color: ${({ theme }) => theme.colors.bodyText};
    color: #AFAFAF;
  }

  svg {
    opacity: 0;
    transition: 100ms ease-in opacity;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};

    svg {
      opacity: 1;
    }
  }
`;

const CareerLink = ({ link, title, location }) => (
  <StyledCareerLink href={link}>
    <Styled.h2
      sx={{
        variant: 'text.light',
        mb: '0',
        mr: '1rem',
        maxWidth: '100%!important',
      }}
    >
      {title}
      <span> in </span>
      {location}
    </Styled.h2>
    <Arrow />
  </StyledCareerLink>
);

export default CareerLink;
