/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useRef } from 'react';
import styled from '@emotion/styled';
import GenericWrapper from './Wrapper';
import mockData from '../data/team';
import { TextSectionGeneric } from './TextSections';
import media from './media';

const Dot = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;
  width: 0.5rem;
  height: 0.5rem;

  ${media.md`
    width: 1rem;
    height: 1rem;
  `}

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    border-radius: 50%;
    background-color: #01a4e9;
    animation: pulse-ring 5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 15px;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 5s cubic-bezier(0.455, 0.03, 0.515, 0.9555) -0.4s infinite;
  }

  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }

  @keyframes pulse-dot {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;

const MapPlusDots = () => (
  <>
    {/* Gurgaon */}
    <Dot
      sx={{
        top: ['44%', '46%', '48%', '49%'],
        left: '70.9%',
        '&:before': { bg: 'secondary' },
        '&:before, &:after': {
          animationDelay: '1.5s',
        },
      }}
    />
    {/* Portland */}
    <Dot
      sx={{
        top: ['32.2%', '33.6%', '34.4%', '35.2%'],
        left: '11.4%',
        '&:before': { bg: 'accent' },
      }}
    />
    <img src={mockData.locations2.map} alt="Map" sx={{ width: '100%' }} />
  </>
);

const TeamLocations = () => {
  const { locations2 } = mockData;

  return (
    <div sx={{ variant: 'invert' }}>
      <TextSectionGeneric title={locations2.headline}>
        {/* Title, sans title */}
        <div
          sx={{
            gridColumn: ['', '', 'span 2'],
            p: {
              mb: ['', '', '0'],
            },
          }}
        >
          <Styled.p>{locations2.copy}</Styled.p>
        </div>

        {/* Mobile Map */}
        <div sx={{ position: 'relative', variant: 'mq.hide.md' }}>
          <MapPlusDots />
        </div>
        {locations2.offices.map((x, i) => (
          <div key={i}>
            <Styled.h3>{x.name}</Styled.h3>
            <Styled.p sx={{ color: 'inherit' }}>{x.description}</Styled.p>
          </div>
        ))}
      </TextSectionGeneric>
      {/* Tablet+ Map */}
      <GenericWrapper sx={{ display: ['none', 'none', 'block'] }}>
        <MapPlusDots />
      </GenericWrapper>
    </div>
  );
};

export default TeamLocations;
