/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import React from 'react'
import Layout from '../components/layout'
import GenericWrapper from '../components/Wrapper'
import mockData from '../data/team'
import QuoteSection from '../components/QuoteSection'
import TeamLocations from '../components/TeamLocations'
import TeamMembership from '../components/TeamMembership'
import TeamCareers from '../components/TeamCareers'
import TeamHero from '../components/TeamHero'

const TeamPage = () => {
  // org mock data
  const { quote } = mockData

  return (
    <Layout>
      <div>
        {/* New hero */}
        <TeamHero />

        {/* Global Teams */}
        <TeamLocations />

        {/* Quote */}
        <QuoteSection
          copy={quote.quote}
          author={quote.author}
          darkMode
          scale="primary"
        />

        <GenericWrapper sx={{ py: ['80px', '80px', '80px', '120px'] }}>
          {/* this important bombs the generic wrapper; need to refactor wierd extra padding */}
          <div sx={{ py: '0px' }}>
            {/* Team Members */}
            <TeamMembership />

            {/* Careers New */}

            {/* <TeamCareers /> */}
          </div>
        </GenericWrapper>
      </div>
    </Layout>
  )
}

export default TeamPage
