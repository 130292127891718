/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useSpring, animated } from 'react-spring';
import media from './media';
import HeroGrid from './HeroGrid';

const TeamHeroGrid = styled(HeroGrid)`
  grid-template-areas:
    'cta      cta       cta'
    'cta      cta       cta'
    'cta      cta       cta'
    '.      block-3     image-3'
    'image-1  block-1   image-3'
    'image-2  image-2 block-2';

  ${media.md`
      margin-top: 76px;
      grid-template-areas:
      'image-1 block-5   image-2   image-2 '
      'cta      cta       cta     block-3'
      'cta      cta       cta     image-3'
      'cta      cta       cta     image-3'
      'block-1  block-52   image-5 block-2'
      'image-4  image-4   block-12 block-4'
      ;
    `}

  ${media.lg`
      margin-top: 55px;
      grid-template-areas:
          'image-1    block-5   image-2   image-2   block-3'
          'block-1    cta       cta       .         image-3'
          'block-52   cta       cta       block-2   image-3'
          'image-4    image-4   block-12  image-5  block-4';
    `}
`;

export default TeamHeroGrid;
