import Parr from '../assets/team/avatars/Ryan.jpg'
import Rohan from '../assets/team/avatars/Rohan.jpg'
import Michelle from '../assets/team/avatars/Michelle.jpg'
import Vikram from '../assets/team/avatars/Vikram.jpg'
import Deepak from '../assets/team/avatars/Deepak.jpg'
import Duc from '../assets/team/avatars/Duc.jpg'
import Ivan from '../assets/team/avatars/Ivan.jpg'
import Lokesh from '../assets/team/avatars/Lokesh.jpg'
import Mitali from '../assets/team/avatars/Mitali.jpg'
import Parker from '../assets/team/avatars/Parker.jpg'
import Rimpy from '../assets/team/avatars/Rimpy.jpg'
import Sarath from '../assets/team/avatars/Sarath.jpg'
import Shivam from '../assets/team/avatars/Shivam.jpg'
import Taylor from '../assets/team/avatars/Taylor.jpg'
import Yashvardhan from '../assets/team/avatars/Yashvardhan.jpg'
import Suhani from '../assets/team/avatars/Suhani.jpg'
import KP from '../assets/team/avatars/KP.jpg'
import NoPic from '../assets/team/avatars/NoPic.jpg'
import Chetan from '../assets/team/avatars/Chetan.jpg'
import Riya from '../assets/team/avatars/Riya.jpg'
import Nawaz from '../assets/team/avatars/Nawaz.jpg'
import Darshan from '../assets/team/avatars/Darshan.jpg'

const membership = [
  // {
  //   id: 'leadership',
  //   description: 'showing us the light',
  //   people: [
  //     {
  //       name: 'Ryan Parr',
  //       title: 'VP, Design & Research',
  //       image: Parr,
  //       isLeadership: true,
  //     },
  //     // {
  //     //   name: 'Rohan Vijay',
  //     //   title: 'Director, Product Design',
  //     //   image: Rohan,
  //     //   isLeadership: true,
  //     // },
  //     {
  //       name: 'Michelle Brannan',
  //       title: 'AVP, UX Design',
  //       image: Michelle,
  //       isLeadership: true,
  //     },
  //     // {
  //     //   name: 'Vikram Aditya',
  //     //   title: 'Associate Director, Product Design',
  //     //   image: Vikram,
  //     //   isLeadership: true,
  //     // },
  //   ],
  // },
  {
    id: 'pixelPushers',
    description: 'Team',
    people: [
      {
        name: 'Ryan Parr',
        title: 'VP, Design & Research',
        image: Parr,
        isLeadership: true,
      },
      {
        name: 'Michelle Brannan',
        title: 'AVP, UX Design',
        image: Michelle,
        isLeadership: true,
      },
      {
        name: 'Suhani Ashok',
        title: 'Product Designer',
        image: Suhani,
      },
      {
        name: 'Duc Bao',
        title: 'Product Designer',
        image: Duc,
      },
      {
        name: 'Rimpy Batra',
        title: 'Lead, Product Designer',
        image: Rimpy,
      },
      {
        name: 'Yashvardhan Chauhan',
        title: 'Senior Product Designer',
        image: Yashvardhan,
      },
      {
        name: 'Deepak Dalal',
        title: 'Senior Product Designer',
        image: Deepak,
      },
      {
        name: 'Lokesh Fulfagar',
        title: 'Product Designer',
        image: Lokesh,
      },
      {
        name: 'Parker Gindele',
        title: 'Visual Designer',
        image: Parker,
      },
      {
        name: 'Ivan Limongan',
        title: 'Visual Designer',
        image: Ivan,
      },
      {
        name: 'Mitali Mantri',
        title: 'Lead Product Designer',
        image: Mitali,
      },
      {
        name: 'Sarath Paliyath',
        title: 'Senior Product Designer',
        image: Sarath,
      },
      // {
      //   name: 'Krishanpal Singh',
      //   title: 'Senior Product Designer',
      //   image: KP,
      // },
      {
        name: 'Shivam Sunderam',
        title: 'Product Designer',
        image: Shivam,
      },
      {
        name: 'Taylor Yeerong',
        title: 'Product Designer',
        image: Taylor,
      },
      // {
      //   name: 'Darshan Kumawat',
      //   title: 'Product Designer',
      //   image: Darshan,
      // },
      // {
      //   name: 'Nawaz',
      //   title: 'Product Designer',
      //   image: Nawaz,
      // },
      {
        name: 'Riya Allen',
        title: 'Illustrator',
        image: Riya,
      },
      // {
      //   name: 'Chetan Manda',
      //   title: 'Product Designer',
      //   image: Chetan,
      // },
    ],
  },
]

export default membership
