/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';
import mockData from '../data/team';
import { TeamMember, TeamGrid } from './Team';

const TeamMembership = () => {
  const { membership } = mockData;
  return (
    <>
      {membership.map((x, i) => (
        <section sx={{ mb: ['80px', '80px', '120px'] }} key={i}>
          <Styled.h2>{x.description}</Styled.h2>
          <TeamGrid>
            {x.people.map((member, j) => (
              <TeamMember key={j} image={member.image} name={member.name} title={member.title} />
            ))}
          </TeamGrid>
        </section>
      ))}
    </>
  );
};

export default TeamMembership;
