/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';
import mockData from '../data/team';
import CareerLink from './CareerLink';

const TeamCareers = () => {
  const { careers } = mockData;

  return (
    <section>
      <Styled.h2>{careers.headline}</Styled.h2>

      <div
        sx={{
          display: ['block', 'block', 'block', 'flex'],
          '& > *': {
            flex: '1 1 50%',
          },
        }}
      >
        {/* Positions Column */}
        <div>
          {careers.positions.map((x, i) => (
            <CareerLink link={x.link} title={x.title} location={x.location} key={i} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamCareers;
