import square1 from '../assets/hero/hero_team-square-1.jpg'
import square2 from '../assets/hero/hero_team-square-2.jpg'
import wide1 from '../assets/hero/hero_team-wide-1.jpg'
import wide2 from '../assets/hero/hero_team-wide-2.jpg'
import tall from '../assets/hero/hero_team-tall.jpg'

import mapSource from '../assets/team/map-no-dots.svg'
// import mapSource from '../assets/team/map-with-dots.svg';
import membership from './team-members'

const hero = {
  title: 'we are design',
  headline: 'Make it simple, but significant.',
  images: [square1, wide2, tall, wide1, square2],
}

const locations2 = {
  headline: 'World wide design',
  copy:
    'Sprinklr Design team is a fantastic group of product designers, visual designers, and UX designers, creating stunning world-class digital experiences for the enterprise. We love this stuff. ;)',
  offices: [
    {
      name: 'North America',
      description:
        'Headquarters in Portland, OR. Known for its young and progressive culture, hot food scene, and made even more famous by the show Portlandia, Portland, OR has quickly become an “it” city for many designers.',
    },
    {
      name: 'Asia + Europe',
      description:
        'Headquarters in Gurgaon, India. A major city in the NCR of Delhi. The city is popularly known as ‘Cyber City’ and ‘Millennium City’ as it has several IT companies, including Amazon, Google, IBM, Cisco, and Sprinklr.',
    },
  ],
  map: mapSource,
}

const quote = {
  author: 'Ryan Parr, VP of Product Design',
  quote:
    '“Product design is where creativity meets functionality, turning ideas into products.”',
}

const careers = {
  headline: 'join the sprinklr design team',
  positions: [
    {
      title: 'product designer',
      location: 'Portland, OR, Remote',
      link: 'https://boards.greenhouse.io/sprinklr/jobs/4208038003',
    },
    {
      title: 'product designer',
      location: 'Portland, OR',
      link: 'https://boards.greenhouse.io/sprinklr/jobs/4208038003',
    },
    {
      title: 'product designer',
      location: 'Portland, OR',
      link: 'https://boards.greenhouse.io/sprinklr/jobs/4208038003',
    },
    {
      title: 'ux designer',
      location: 'Portland, OR',
      link: 'https://boards.greenhouse.io/sprinklr/jobs/4168151003',
    },
    {
      title: 'ux designer',
      location: 'Portland, OR',
      link: 'https://boards.greenhouse.io/sprinklr/jobs/4168151003',
    },
    {
      title: 'ux researcher',
      location: 'Portland, OR',
      link: 'https://boards.greenhouse.io/sprinklr/jobs/4216140003',
    },
    {
      title: 'design program manager',
      location: 'Portland, OR',
      link: 'https://boards.greenhouse.io/sprinklr/jobs/4216577003',
    },
  ],
}

const teamPage = {
  hero,
  membership,
  quote,
  locations2,
  careers,
}

export default teamPage
