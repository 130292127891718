/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';
import { useSpring, animated } from 'react-spring';
import mockData from '../data/team';
// import { TeamHeroGrid } from './HomeGridSection';
import TeamHeroGrid from './TeamHeroGrid';
import HeroCTA from './HeroCTA';

const TeamHero = () => {
  const { hero } = mockData;

  const springProps = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: {
      opacity: 0,
      transform: 'translateY(100px)',
    },
  });

  return (
    <div sx={{ bg: 'text' }}>
      <TeamHeroGrid iteration={4} sx={{ variant: 'invert' }}>
        {/* Color Blocks */}
        {new Array(5).fill('your fav could nevuh').map((x, i) => (
          <div
            key={`block-${i}`}
            sx={{
              gridArea: `block-${i + 1}`,
              backgroundColor: theme => `${theme.colors.scale.accent[i]}`,
            }}
          />
        ))}
        <div sx={{ gridArea: 'block-12', backgroundColor: theme => `${theme.colors.scale.accent[0]}` }} />
        <div sx={{ gridArea: 'block-52', backgroundColor: theme => `${theme.colors.scale.accent[4]}` }} />

        {/* Photos */}
        {hero.images.map((x, i) => (
          <div
            sx={{
              variant: 'backgroundImage',
              gridArea: `image-${i + 1}`,
              backgroundImage: `url(${x})`,
            }}
            key={`image-${i}`}
          />
        ))}

        {/* Content */}
        <HeroCTA animateLoadingState title={hero.title} headline={hero.headline} />
      </TeamHeroGrid>
    </div>
  );
};

export default TeamHero;
